import { createEffect, createMemo, createSignal, Show } from 'solid-js'
import { TypeProofreadingEntryProps } from '~/components/type-proofreading-entry/type-proofreading-entry.interfaces'
import styles from '~/components/type-proofreading-entry/type-proofreading-entry.module.scss'
import { highlightTranslation, translations } from '~/services/current-document/translations'
import Comments from '~/components/comments/comments'
import { highlightTypesetText, typesetTexts } from '~/services/current-document/typeset-texts'

const TypeProofreadingEntry = ({ translationId }: TypeProofreadingEntryProps) => {
  const typesetTextEntry = createMemo(() => typesetTexts().find(typesetText => typesetText.translationId === translationId))
  const translationEntry = () => translations().find(translation => translation.id === translationId)
  const index = createMemo(() => translations().findIndex(translation => translation.id === translationId) + 1)
  const [hover, setHover] = createSignal(false)

  let sideviewElementRef!: HTMLDivElement

  // Event listeners
  const onHover = () => {
    setHover(true)
  }
  const onBlur = () => {
    setHover(false)
  }
  const onClick = () => {
    const transEntry = translationEntry()
    const typeEntry = typesetTextEntry()
    if(transEntry){
      highlightTranslation(transEntry, { scrollView: 'canvas' })
    }
    if(typeEntry){
      highlightTypesetText(typeEntry, { scrollView: 'canvas' })
    }
  }

  // Ref Setters
  createEffect(() => {
    const typeEntry = typesetTextEntry()
    const transEntry = translationEntry()
    if(typeEntry && !typeEntry.sideviewElementRef){
      typeEntry.sideviewElementRef = sideviewElementRef
    }
    if(transEntry && !transEntry.sideviewElementRef){
      transEntry.sideviewElementRef = sideviewElementRef
    }
  })

  // Styles
  const entryStyles = () => ({
    [`${styles.typeProofreadingEntry}`]: true,
    [`${styles.selected}`]: translationEntry()?.highlighted,
    [`${styles.isOnomatopoeia}`]: translationEntry()?.isOnomatopoeia,
    [`${styles.isCorrection}`]: translationEntry()?.isCorrection
  })

  return (
    <>
      <div
        classList={entryStyles()}
        ref={sideviewElementRef}
        onMouseEnter={onHover}
        onMouseLeave={onBlur}
        onClick={onClick}
      >
        <div class={styles.head}>
          <div class={styles.index}>
            #{index()}
          </div>
        </div>
        <div class={styles.translatedText}>
          {translationEntry()?.translatedText}
        </div>
        <Show when={translationEntry()}>
          {translation => (
            <Comments
              entry={translation()}
              visible={hover()}
            />
          )}
        </Show>
      </div>
    </>
  )
}

export default TypeProofreadingEntry